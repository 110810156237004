export const breadcrumblist = [
  [
    {
      name: "classroomA",
      pathname: "课堂",

      children: [
        {
          name: "classroom",
          pathname: "课堂",
          isShow: false,
        },
        {
          name: "classroomrecords",
          pathname: "课堂记录",
        },
        {
          name: "preparation",
          pathname: "课堂记录详情",
        },
        {
          name: "classstyle",
          pathname: "课堂记录详情",
        },

        {
          name: "attendance",
          pathname: "课堂记录详情",
        },
        {
          name: "classreport",
          pathname: "课堂报告",
        },
        {
          name: "studentAttendanceRecords",
          pathname: "学生体育课出勤情况",
        },
        {
          name: "studentAttRecordsbreaktime",
          pathname: "学生大课间出勤情况",
        },
        {
          name: "homework",
          pathname: "学生家庭作业情况",
        },
        {
          name: "studentSportsTwoAndOne",
          pathname: "学生体质健康",
        },
      ],
    },
    {
      name: "tasklistA",
      pathname: "作业",
      children: [
        {
          name: "tasklist",
          pathname: "作业管理",
        },
        {
          name: "taskdetail",
          pathname: "作业详情",
        },
        {
          name: "assignment",
          pathname: "布置作业",
        },
        {
          name: "task_rate",
          pathname: "作业点评",
        },
      ],
    },
    {
      name: "evaluation",
      pathname: "教育决策",
      children: [
        {
          name: "teachingModel",
          pathname: "分层教学模型",
          isShow: false,
        },
        {
          name: "studentlist",
          pathname: "学生列表",
        },
        {
          name: "studentLiteracydetail",
          pathname: "学生综合评价",
        },
        {
          name: "studentLiteracy",
          pathname: "学生核心素养",
          isShow: false,
        },
        {
          name: "gradelist",
          pathname: "年级列表",
        },
        {
          name: "classlist",
          pathname: "班级列表",
        },
        ,
      ],
    },
    {
      name: "attainmentA",
      pathname: "体质健康",
      children: [
        {
          name: "attainment",
          pathname: "体质健康",
        },
        {
          name: "studentPhysical",
          pathname: "学生健康档案",
        },
        {
          name: "classExamination",
          pathname: "中考模考",
        },
        {
          name: "studentExamination",
          pathname: "学生中考模考",
        },
      ],
    },
    {
      name: "gamesA",
      pathname: "赛事",
      children: [
        {
          name: "games",
          pathname: "智慧跑步",
        },
        {
          name: "competition",
          pathname: "智慧跑步列表",
        },
        {
          name: "realTimeScore",
          pathname: "智慧跑步查看成绩",
        },
      ],
    },
    {
      name: "resourcesA",
      pathname: "资源库",
      children: [
        {
          name: "resources",
          pathname: "资源库",
        },
      ],
    },
    {
      name: "mynoticeA",
      pathname: "通知",
      children: [
        {
          name: "mynotice",
          pathname: "通知",
        },
      ],
    },
    {
      name: "intelligentA",
      pathname: "设备",
      children: [
        {
          name: "intelligent",
          pathname: "设备",
        },
      ],
    },
  ],
];
