<template>
  <el-container>
    <el-container>
      <!-- <el-aside class="aside" style="/*width: 300px;*/"> -->
      <!-- <el-menu class="el-menu-vertical-demo" :default-active="active" @select="handleSelect" :background-color="color"
          text-color="#fff" active-text-color="#ffd04b" router>
          <el-menu-item index="index">
            <img class="imgstyle" src="../assets/index.png" alt="">
            <span slot="title">首页</span>
          </el-menu-item>
          <el-menu-item index="classroom">
            <img class="imgstyle" src="../assets/classroom.png" alt="">
            <span slot="title">课堂</span>
          </el-menu-item>
          <el-menu-item index="job">
            <img class="imgstyle" src="../assets/job.png" alt="">
            <span slot="title">作业</span>
          </el-menu-item>
          <el-menu-item index="games">
            <img class="imgstyle" src="../assets/games.png" alt="">
            <span slot="title">赛事</span>
          </el-menu-item>
          <el-menu-item index="user">
            <img class="imgstyle" src="../assets/user.png" alt="">
            <span slot="title">我的</span>
          </el-menu-item>
        </el-menu> -->
      <el-menu
        :default-active="active"
        :collapse="isCollapse"
        :background-color="color"
        text-color="#fff"
        active-text-color="#fff"
        router>
        <div class="title">
          <div class="block" @click="gotoPersonalCenter">
            <!-- <img style="width: 100%; height: 100%;" :src="info.avatar_url" alt=""> -->
            <el-avatar
              :fit="fill"
              :size="50"
              :src="info.avatar_url"></el-avatar>
          </div>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              {{ info.user_show_name }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="outuser"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <div>
              <div @click="gb">
                <i :laber="false" class="el-icon-s-fold"></i>
              </div>
            </div> -->
        </div>
        <div v-for="(i, j) in leftlist" :key="j">
          <el-menu-item
            :index="i.index"
            @click="change(i)"
            :class="{ zykys: zykys == j }">
            <img class="imgstyle" :src="i.imgs" alt="" />
            <span slot="title">{{ i.name }}</span>
          </el-menu-item>
        </div>
        <div style="display: none">
          <el-menu-item index="PersonalCenter">个人中心</el-menu-item>
        </div>
      </el-menu>
      <!-- </el-aside> -->
      <el-main style="height: 100%">
        <el-breadcrumb
          class="crumb flexSt"
          v-if="currentBreadcrumblist.length != 0"
          separator="/">
          <el-breadcrumb-item :to="{ path: '/index' }">
            <i class="iconfont icon-antOutline-home"></i
          ></el-breadcrumb-item>
          <el-breadcrumb-item
            v-for="(item, index) in currentBreadcrumblist"
            :index="index">
            <a
              v-if="index != 0 && item.query"
              :href="'/' + item.name + '?' + item.query"
              >{{ item.pathname }}
            </a>
            <a v-if="index != 0 && !item.query" :href="'/' + item.name"
              >{{ item.pathname }}
            </a>

            <span v-if="index == 0 && currentBreadcrumblist.length == 1">
              {{ item.pathname }}
            </span>
            <a
              v-if="index == 0 && currentBreadcrumblist.length > 1"
              :href="'/' + item.name"
              >{{ item.pathname }}
            </a>
          </el-breadcrumb-item>
        </el-breadcrumb>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import { breadcrumblist } from "@/utils/breadcrumblist";
export default {
  watch: {
    $route: {
      immediate: true,
      // 深度观察监听
      deep: true,
      async handler(to, from) {
        const container = document.querySelector(".el-main");
        if (container) {
          container.scrollTo(0, 0);
        }
        this.$forceUpdate();
        console.log(to, "路由变化");
        if (to.name == "index") {
          this.currentBreadcrumblist = [];
          return;
        }
        let listlength = to.matched.findIndex((a) => {
          return a.name == to.name;
        });
        this.currentBreadcrumblist = [];
        for (let i = 0; i < listlength + 1; i++) {
          this.currentBreadcrumblist.push({
            name: to.matched[i].name,
            pathname: "",
          });
        }

        let routnamelist = [
          "classroomrecordsdetail",
          "taskdetail",
          "studentPhysical",
          "studentExamination",
        ];
        let findIndex = routnamelist.findIndex((item) => {
          return to.matched[1].name == item;
        });

        console.log(findIndex, "===findIndex");
        if (findIndex != -1) {
          await this.currentBreadcrumblist.splice(0, 2);
          this.handleClass(to.matched[1].name);
          this.handleEvaluation(to.matched[1].name);
          this.handleTask(to.matched[1].name);
          this.handleattainmentA(to.matched[1].name);
          this.handlegamesA(to.matched[1].name);
        }

        let length = this.currentBreadcrumblist.length;

        breadcrumblist.forEach((i, m) => {
          i.forEach((a, b) => {
            // console.log(
            //   a.name,
            //   to.matched[0].name,
            //   "==a.name == to.matched[0].name"
            // );
            if (a.name == to.matched[0].name) {
              this.currentBreadcrumblist[0].pathname = a.pathname;
              console.log(
                a.name,
                to.matched[0].name,

                "这是两个相等"
              );
              // 第一级

              a.children.forEach((item, index) => {
                if (length > 2) {
                  console.log(
                    item.name,
                    to.name,
                    this.currentBreadcrumblist,
                    "==========这是长度大于 2"
                  );

                  if (item.isShow == false) {
                    return (this.currentBreadcrumblist = []);
                  }

                  if (item.name == this.currentBreadcrumblist[1].name) {
                    console.log("这是进来了第一季第一个判断");
                    let itemIndex = item.children.findIndex((m) => {
                      return m.name == this.currentBreadcrumblist[2].name;
                    });
                    this.currentBreadcrumblist[1].pathname = item.pathname;

                    if (itemIndex != -1) {
                      this.currentBreadcrumblist[2].pathname =
                        item.children[itemIndex].pathname;
                      //   体育活动
                    }
                  }

                  // 路由处理
                  // this.handleClass(
                  //   to.matched[1].name,
                  //   this.currentBreadcrumblist
                  // );

                  // 第二级
                } else {
                  console.log(
                    item.name,
                    to.name,
                    item.pathname,
                    this.currentBreadcrumblist,

                    "==========进来了第二季长度少于2"
                  );
                  if (item.name == to.name) {
                    console.log(
                      item.name,
                      to.name,
                      item.pathname,
                      this.currentBreadcrumblist,

                      "==========进来了第二季长度少于2"
                    );
                    if (item.isShow == false) {
                      return (this.currentBreadcrumblist = []);
                    }
                    if (!this.currentBreadcrumblist[1]) {
                      this.currentBreadcrumblist[1] = {
                        name: "",
                        pathname: "",
                      };
                    }
                    this.currentBreadcrumblist[1].pathname = item.pathname;

                    // 第一层菜单的处理
                    // 课堂

                    let routnamelist = [
                      "tasklistA",
                      "classroomA",
                      "evaluation",
                      "attainmentA",
                      "gamesA",
                      "resourcesA",
                      "mynoticeA",
                      "intelligentA",
                    ];

                    let findIndex = routnamelist.findIndex((item) => {
                      return to.matched[0].name == item;
                    });
                    console.log(
                      findIndex,
                      to.matched[0].name,
                      "===findIndex1111111111111"
                    );
                    if (findIndex != -1) {
                      this.currentBreadcrumblist.splice(0, 1);
                      this.handleClass(to.matched[1].name);
                      this.handleTask(to.matched[1].name);
                      this.handleEvaluation(to.matched[1].name);
                      this.handleattainmentA(to.matched[1].name);
                      this.handlegamesA(to.matched[1].name);
                    }
                  }
                }
              });
            }
          });
        });
        console.log(
          this.currentBreadcrumblist,
          "====    console.log(  this.currentBreadcrumblist);"
        );
      },
    },
  },
  data() {
    return {
      currentBreadcrumblist: [],
      fill: "fill",
      zykys: 5,
      circleUrl: "",
      username: "",
      leftlist: [
        {
          name: "首页",
          index: "index",
          imgs: require("../assets/home/路径 46721.png"),
        },
        {
          name: "课堂",
          index: "classroom",
          imgs: require("../assets/home/组 7460.png"),
        },
        {
          name: "课堂记录",
          index: "classroomrecords",
          imgs: require("../assets/home/组 269@2x.png"),
        },
        {
          name: "作业",
          index: "tasklist",
          imgs: require("../assets/home/组 7462.png"),
        },
        {
          name: "分层教学",
          index: "teachingModel",
          imgs: require("../assets/home/fc.png"),
        },
        {
          name: "体质健康",
          index: "attainment",
          imgs: require("../assets/home/组 11645@2x.png"),
        },
        {
          name: "赛事",
          index: "games",
          imgs: require("../assets/home/路径 46720.png"),
        },
        {
          name: "素养",
          index: "studentLiteracy",
          imgs: require("../assets/home/组 7463.png"),
        },
        {
          name: "资源库",
          index: "resources",
          imgs: require("../assets/home/组 7464.png"),
        },
        {
          name: "通知",
          index: "mynotice",
          imgs: require("../assets/home/路径 46719.png"),
        },
        {
          name: "设备",
          index: "intelligent",
          imgs: require("../assets/home/dianchi.png"),
        },
      ],
      color: "#516CF7",
      bgstyle: "",
      user: null,
      admin: {},
      info: {},
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.username = this.user.user_show_name;
    this.getinof();
    console.log(this.user, "this.userthis.user");
    // this.get_auth_me()
  },
  computed: {
    active() {
      return this.$route.path.slice(1).split("-")[0];
    },
    isCollapse() {
      return this.$store.state.isCollapse;
    },
  },
  methods: {
    handlegamesA(val) {
      let list = ["competition"];
      console.log(list.includes(val));
      if (list.includes(val)) {
        let obj = {
          name: "games",
          pathname: "智慧跑步",
        };
        this.currentBreadcrumblist.unshift(obj);
        console.log(this.currentBreadcrumblist, "这是完全修改后");
      }
    },
    handleattainmentA(val) {
      console.log(val, this.currentBreadcrumblist, "进来了删除后的");
      let list = ["studentPhysical"];
      console.log(list.includes(val));
      if (list.includes(val)) {
        let obj = {
          name: "attainment",
          pathname: "体质健康",
        };
        this.currentBreadcrumblist.unshift(obj);
        console.log(this.currentBreadcrumblist, "这是完全修改后");
      }

      let list2 = ["studentExamination"];
      if (list2.includes(val)) {
        let obj = {
          name: "classExamination",
          pathname: "中考模块",
        };
        this.currentBreadcrumblist.unshift(obj);
        console.log(this.currentBreadcrumblist, "这是完全修改后");
      }
    },
    handleEvaluation(val) {
      let list = [
        "studentlist",
        "studentLiteracydetail",
        "gradelist",
        "classlist",
      ];

      if (list.includes(val)) {
        let obj = {
          name: "teachingModel",
          pathname: "分层教学模型",
        };
        this.currentBreadcrumblist.unshift(obj);
      }
    },
    handleClass(val) {
      let list = [
        "classreport",
        "classroomrecordsdetail",
        "studentAttendanceRecords",
        "studentAttRecordsbreaktime",
        "homework",
        "studentSportsTwoAndOne",
      ];
      console.log(list.includes(val), "课堂");
      if (list.includes(val)) {
        let obj = {
          name: "classroomrecords",
          pathname: "课堂记录",
        };
        this.currentBreadcrumblist.unshift(obj);
      }
    },
    handleTask(val) {
      let list = ["taskdetail", "assignment", "task_rate"];
      if (list.includes(val)) {
        let obj = {
          name: "tasklist",
          pathname: "作业管理",
        };
        this.currentBreadcrumblist.unshift(obj);
      }
    },
    async getinof() {
      const res = await this.axios.get("/auth/info");
      this.info = res.data;
    },

    gotoPersonalCenter() {
      this.$router.push("/PersonalCenter");
    },
    gb() {
      alert("ssss");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect() {},
    handleTogo() {
      this.$router.push("/largescreen");
    },
    change() {},
    changeColor(val) {
      this.bgstyle.background = val;
      this.color = val;
      localStorage.setItem("color", val);
      localStorage.setItem("bgstyle", JSON.stringify({ background: val }));
    },
    outuser() {
      localStorage.setItem("token", "");
      this.$router.push({ name: "login" });
    },
    async get_auth_me() {
      const res = await this.axios.get("/auth/me");
      if (res.code === 1) {
        this.admin = res.data.admin;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.crumb {
  // height: 75px;
  // line-height: 75px;
  font-size: 18px;
  margin-left: 43px;
  margin-top: 40px;
}
.title {
  width: 100%;
  padding-top: 62px;
  text-align: center;
  padding-bottom: 87px;
  .block {
    // width: 90px;
    // height: 90px;
    // border-radius: 100%;
    padding-bottom: 13px;
  }
  .username {
    font-size: 22px;
    color: #fff;
  }
  .el-dropdown {
    color: #fff;
    font-size: 22px;
    cursor: pointer;
  }
}
.zykys {
  // padding-left: 40px !important;
}
.imgstyle {
  // width: 37px; height:34px ;position: absolute;left: 34px;top: 10px;
  width: 25px;
  height: 25px;
  padding-right: 20px;
}

.largebox {
  cursor: pointer;

  .img_l {
    width: 34px;
    height: 31px;
    margin-left: 46px;
    margin-right: 26px;
  }
}

.large {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a80f7;
}

/deep/.el-menu-item {
  // font-size: 22px;
  font-size: 20px;
  height: 65px;
  line-height: 65px;
  margin-left: 9px;
}

.el-menu-vertical-demo {
  width: 172px;
  height: 1080px;
  background: #516cf7;
  padding-top: 20px;
}

.el-card__body,
.el-main {
  padding: 0px !important;
}

.el-container {
  height: 100vh;
  background: #f5f6fa;
  overflow: hidden;
}

/deep/ .el-menu {
}

.el-aside {
  box-shadow: 4px 16px 48px -8px rgba(102, 169, 255, 0.46);
  width: 110px !important;
  // border-radius: 0px 0px 60px 0px;
  .el-menu {
    // width: 172px;
    width: 172px;
    height: 100%;
  }
}

// .el-main {
//   padding: 0;
// }
.el-header {
  padding: 0;
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .login {
    // width: 220px;
    height: 100%;
    text-align: left;
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    // background-color: #226bff;
    color: #226bff;
    // border-radius: 0px 60px 0px 0px;
    padding-left: 20px;
    // line-height: 60px;
    padding-top: 12px;
    margin-right: 40px;
  }

  .user {
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: space-between;

    img {
      width: 40px;
      border-radius: 50%;
    }
  }
}

.el-main {
  background-color: #f2f7ff;
}

/deep/.el-submenu__title {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-align: left;
}

/deep/.el-submenu__title i {
  color: #fff;
}

/deep/.el-menu-item-group__title {
  padding: 0;
}
/deep/ .el-avatar--circle {
  height: 73px !important;
  width: 73px !important;
}
.is-active {
  background-image: url("../assets/home/组 7428.png");
  background-size: 100% auto;
}
</style>
<style>
.menu {
  padding-left: 35px !important;
}
.el-menu {
  width: 172px !important;
}

.el-menu--collapse {
  width: 110px !important;
}
</style>
